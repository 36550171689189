export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const upperCaseText = (t) => {
  return t ? t.toString().toUpperCase() : "";
};

export const getFullAddress = (address, st, city, zip) => {
  let r =
    upperCaseText(address) +
    " " +
    //upperCaseText(city) +
    //" " +
    // ------->>>>> Commenting City from this code so only Address, State and Zip are returned,
    // and city is no part of the comparison to increase potential matches.
    upperCaseText(st) +
    " " +
    zip;

  return formatAddress(r);
};

export const formatAddress = (address) => {
  let r = upperCaseText(address);

  return r
    .replace(" WEST ", " W ")
    .replace(" NORTH ", " N ")
    .replace(" EAST ", " E ")
    .replace(" SOUTH ", " S ")
    .replace(" AVENUE ", " AVE ")
    .replace(" BOULEVARD ", " BLVD ")
    .replace(" #", " APT ")
    .replace(" UNIT ", " APT ")
    .replace(" DRIVE ", " DR ")
    .replace(" COURT ", " CT ")
    .replace(" LANE ", " LN ")
    .replace(" ROAD ", " RD ")
    .replace(" UNIT ", " APT ")
    .replace(".", "")
    .replace(",", "")
    .replace("  ", " ");
};

export const getDate = (ini) => {
  return ini
    ? new Date(
        new Date(ini).getUTCFullYear(),
        new Date(ini).getUTCMonth(),
        new Date(ini).getUTCDate()
      )
    : new Date(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate()
      );
};

export function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  // edit: increment months if d2 comes later in its month than d1 in its month
  if (d2.getDate() >= d1.getDate()) months++;
  // end edit
  return months <= 0 ? 0 : months;
}

export function dayDiff(d1, d2) {
  let Difference_In_Time = d1.getTime() - d2.getTime();
  // To calculate the no. of days between two dates
  return Difference_In_Time / (1000 * 3600 * 24);
}
