import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import { query } from "../API";
import { Row } from "react-bootstrap";
import Loader from "../Loader";
import { queryPatientsFromNexHealth } from "../API/nexhealth.js";

const ClientList = () => {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  context.gargleRequestKey = "4c590b60-5163-4d7a-9ed8-88f0d73a71b2";
  const columns = [
    "First Visit Date",
    "First Name",
    "Last Name",
    "Street",
    "City",
    "State",
    "Zip Code",
  ];

  const getUrlParameter = (paramName) => {
    let paramValue = null;
    let params = new URLSearchParams(window.location.search);

    for (let p of params) {
      if (p[0].toLocaleLowerCase() === paramName) {
        paramValue = p[1];
      }
    }

    return paramValue;
  };

  const subdomain = getUrlParameter("subdomain");
  const accountid = getUrlParameter("accountid");
  const limit = getUrlParameter("limit") ? getUrlParameter("limit") : 200;
  const isNexHealth = subdomain !== null; //Only NexHealth call sets subdomain

  useEffect(() => {
    if (!data && accountid) {
      if (!isNexHealth) {
        query(
          "DPMS_Patient__c",
          "Id, First_Visit_Date__c, First_Name__c, Last_Name__c, Street_Str__c, City_Str__c, State_Str__c, Zip_Str__c, Status__c",
          "Status__c LIKE 'Activ%' AND (Account__c='" +
            accountid +
            "' OR Account__r.OLD_SF_ID__c='" +
            accountid +
            "') AND Street_Str__c != null AND First_Visit_Date__c != null AND First_Visit_Date__c < TOMORROW ORDER BY First_Visit_Date__c DESC LIMIT " +
            limit,
          context,
          true
        ).then((gargleData) => {
          if (gargleData) setData(gargleData);
          else console.log("No Data");
        });
      } else {
        queryPatientsFromNexHealth(accountid, subdomain, null).then(
          (gargleData) => {
            if (gargleData) setData(gargleData);
            else console.log("No Data");
          }
        );
      }
    }
  });

  if (data) {
    setTimeout(() => {
      window["buildDataTable"]();
    }, 500);
  }

  if (!accountid) {
    return null;
  }

  return (
    <>
      {
        <div className="content" style={{ margin: "0", padding: "40px" }}>
          {!data && <Loader />}
          {data && (
            <>
              <Row>
                <div
                  className="chart-container"
                  style={{ width: "100%", margin: "0 auto" }}
                >
                  <div className="cs-title">{data.length} records</div>
                  <table
                    id="main-table"
                    style={{
                      minWidth: "100%",
                      width: "100%",
                    }}
                    border="0"
                    className="display stripe row-border order-column"
                  >
                    <thead>
                      <tr>
                        {columns.map((c) => {
                          return <th key={"col-" + c}>{c}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((a) => {
                        return (
                          <tr key={"row-" + a.Id}>
                            <td>{a.First_Visit_Date__c}</td>
                            <td>{a.First_Name__c}</td>
                            <td>{a.Last_Name__c}</td>
                            <td>{a.Address__c}</td>
                            <td>{a.City_Str__c}</td>
                            <td>{a.State_Str__c}</td>
                            <td>{a.Zip_Str__c}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Row>
            </>
          )}
        </div>
      }
    </>
  );
};

export default ClientList;
