import axios from "axios";
import { getFullAddress } from "./utils.js";

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

let calloutCount = 0; //NexHealth does not count the authenticates endpoint

const getRecords = async (
  locationId,
  subdomain,
  lastSyncDate,
  token,
  page,
  patientArray
) => {
  let updatedSince =
    lastSyncDate === null ? "" : "&updated_since=" + lastSyncDate;
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      "https://nexhealth.info/patients?subdomain=" +
      subdomain +
      "&location_id=" +
      locationId +
      "&page=" +
      page +
      "&inactive=false&per_page=300&location_strict=true&sort=-created_at&appointment_date_start=2010-01-01" +
      updatedSince,
    headers: {
      Accept: "application/vnd.Nexhealth+json;version=2",
      Authorization: "Bearer " + token,
    },
  };

  /* SAMPLE PAYLOAD
  *
    "id": 197312998,
    "email": null,
    "first_name": "Allen",
    "middle_name": null,
    "last_name": "Allowed",
    "name": "Allen Allowed",
    "created_at": "2023-08-16T14:34:25.661Z",
    "updated_at": "2023-12-19T03:28:43.543Z",
    "institution_id": 7321,
    "foreign_id": "11",
    "foreign_id_type": "msg-opendental-DataSource-8922",
    "bio": {
        "city": "Forbes",
        "state": "CT",
        "gender": "Male",
        "zip_code": "21598",
        "new_patient": false,
        "non_patient": false,
        "phone_number": "",
        "date_of_birth": "1980-06-05",
        "address_line_1": "266 Jamaica Street",
        "address_line_2": "",
        "street_address": "266 Jamaica Street",
        "expired_numbers": [],
        "verified_mobile": null,
        "cell_phone_number": "",
        "home_phone_number": "2536879654",
        "work_phone_number": "",
        "previous_foreign_id": null
    },
    "appointments": [
        {
            "id": 253457544,
            "provider_id": 254238677,
            "provider_name": "Maribel Montalvo",
            "start_time": "2022-09-06T16:10:00.000Z",
            "end_time": "2022-09-06T16:40:00.000Z",
            "location_id": 117290,
            "confirmed": true
        }
    ],
    "inactive": false,
    "last_sync_time": "2023-12-19T03:28:43.543Z",
    "guarantor_id": null,
    "unsubscribe_sms": false,
    "balance": null,
    "location_ids": [
        77263
    ]
  */

  let response = null;

  try {
    response = await axios.request(config);
    calloutCount++;
    console.log("-> Callout Count: " + calloutCount);
  } catch (err) {
    response = null;
    console.log("Error on location::: " + subdomain + " - " + err.message);
  }

  if (response !== null && response.data.data.patients.length > 0) {
    response.data.data.patients.forEach((patient) => {
      if (patient.appointments && patient.appointments.length > 0)
        patientArray.push({
          First_Name__c: patient.first_name,
          Last_Name__c: patient.last_name,
          First_Visit_Date__c: patient.appointments[0].start_time.split("T")[0],
          Address__c: patient.bio.address_line_1,
          Phone__c: patient.bio.phone_number,
          City_Str__c: patient.bio.city,
          State_Str__c: patient.bio.state,
          Zip_Str__c: patient.bio.zip_code,
          FullAddress: getFullAddress(
            patient.bio.address_line_1,
            patient.bio.state,
            patient.bio.city,
            patient.bio.zip_code
          ),
        });
    });

    if (response.data.data.patients.length === 300)
      return Promise.resolve(page + 1);
    else return null; // Less than 300 records escapes search
  } else {
    return Promise.resolve(null);
  }
};

const getAllRecords = async (locationId, subdomain, lastSyncDate, token) => {
  let page = 1;
  const patientArray = [];

  /*****
   * While in transition process, restrict this call to 20 pages in total for the initial mass sync.
   */
  while (page <= 2) {
    page = await getRecords(
      locationId,
      subdomain,
      lastSyncDate,
      token,
      page,
      patientArray
    );
    if (page === null) page = 1000; // Forces escape
  }
  return Promise.resolve(patientArray);
};

/**
 * Public function to be called per location to get the full list of patients
 * CONSIDERATIONS: Limit of patients per page = 300
 * @param {*} locationId
 * @returns
 */
export const queryPatientsFromNexHealth = async (
  locationId,
  subdomain,
  lastSyncDate
) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://nexhealth.info/authenticates",
    headers: {
      Accept: "application/vnd.Nexhealth+json;version=2",
      Authorization: process.env.REACT_APP_NEXHEALTH_KEY,
    },
  };
  calloutCount = 0;

  const response = await axios.request(config);
  const patientArray = await getAllRecords(
    locationId,
    subdomain,
    lastSyncDate,
    response.data.data.token
  );
  return Promise.resolve(patientArray);
};

///// SIMPLE TEST LINE
//const result = await queryPatientsFromNexHealth(77263);
//console.log(result);
