import "./App.css";

import React, { createContext } from "react";

import ClientList from "./components/ClientList";

export const UserContext = createContext(null);

function App() {
  /////////////////////////////////////////////// USE THIS FLAG TO PUT EVERYTHING IN MAINTENANCE
  const inMaintenance = false;

  return (
    <>
      {inMaintenance && (
        <div
          style={{
            height: "100%",
            width: "100%",
            textAlign: "center",
            marginTop: "100px",
          }}
        >
          We are doing some maintenance. We will be back soon :)
        </div>
      )}
      {!inMaintenance && (
        <React.StrictMode>
          <UserContext.Provider value={{ accessToken: null, isLoading: false }}>
            <ClientList />
          </UserContext.Provider>
        </React.StrictMode>
      )}
    </>
  );
}

export default App;
